@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

@font-face {
    font-family: 'EnzoOT';
    src: url('../fonts/EnzoOT-Medi.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EnzoOT';
    src: url('../fonts/EnzoOT-MediIta.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EnzoOT';
    src: url('../fonts/EnzoOT-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EnzoOT';
    src: url('../fonts/EnzoOT-BoldIta.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EnzoOT';
    src: url('../fonts/EnzoOT-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EnzoOT';
    src: url('../fonts/EnzoOT-LightIta.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    src: url('../fonts/MuseoSans_300.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 300;
    font-style: italic;
    src: url('../fonts/MuseoSans_300_Italic.otf') format('opentype');
    font-display: swap;
}


:root {
    --font-family: 'MuseoSans', sans-serif;

    --primary-color: #d3d3d3;

    --text-color: #2e2a28;
    --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

#root, #dct-react {
    all: initial;
    font-family: var(--font-family);
    color: var(--text-color);
}

#root input, #dct-react input{
    box-sizing: border-box;
    border: none;
}

.gm-style-cc {
    display: none !important;
}
